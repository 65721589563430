<template>
    <div>
        <add-shop v-if="editcompondata.disblay" @titleSync="editcompongoToView" :titleSync.sync='editcompondata'></add-shop>
        <div v-else>
            <Row :gutter="16" style="margin-bottom: 24px">
                <Col span="6">
                    <Input placeholder="请输入商品名称" v-model="storePrams.keyword" />
                </Col>
                <Col span="4">
                    <Select v-model="storePrams.mode" clearable style="width: 200px">
                        <Option v-for="item in modeList" :value="item.value" :key="item.value" >{{ item.label }}</Option>
                    </Select>
                </Col>
                <Col span="6">
                    <Button type="primary" icon="ios-search-outline" @click="searchList">搜索</Button >
                </Col>
                </Row>
                <Row :gutter="16" style="margin-bottom: 24px">
                <Col span="6">
                    <Button icon="md-add" type="primary" @click="addNotice('add')" >添加商品</Button>
                </Col>
            </Row>

            <Table border :columns="platformColumns" :data="shopList">
                <template slot-scope="{ row }" slot="mode">
                    <div class="mode_box">
                        <div v-if="row.mode.indexOf(1) != -1">堂食<span v-if="row.mode.indexOf(2) != -1 || row.mode.indexOf(3) != -1">、</span> </div>
                        <div v-if="row.mode.indexOf(2) != -1">外卖<span v-if="row.mode.indexOf(3) != -1">、</span></div>
                        <div v-if="row.mode.indexOf(3) != -1">自取</div>
                    </div>
                </template>
                <template slot-scope="{ row }" slot="operation">
                    <Button type="primary" size="small" style="margin-right: 20px" @click="addNotice(row.id)">编辑</Button>
                    <Button type="error" size="small" style="margin-right: 5px" @click="delBtn(row.id)">删除</Button>
                </template>
            </Table>
            <br />
            <Page :total="total" :current="storePrams.page" style="text-align: right" show-elevator show-total @on-change="pageChange" :page-size="storePrams.limit" />
       </div>
    </div>
</template>
<script>
import {goodslist,goodsdel} from '@/api/shop.js'
import AddShop from './components/addShop.vue'
export default {
    components:{ AddShop },
    data() {
        return {
            platformColumns: [
                {title: "商品ID",key: "id",align:'center'},
                {title: "商品名称",key: "goods_name",align:'center'},
                {title: "价格",key: "price",align:'center'},
                {title: "支持模式",slot:"mode",align:'center'},
                {title: "操作",key: "action",align: "center",slot: "operation"}
            ],
            editcompondata:{// 控制组件
                disblay:false,
                id:null,
                type:1,
                data:{}
            },
            total: 0,
            storePrams: {mode: null,limit: 10,page: 1,keyword: "",},
            modeList: [
                { value: 1, label: "堂食" },
                { value: 2, label: "外卖" },
                { value: 3, label: "自取" },
            ],
            shopList:[]
        };
    },
    created() {
        this.getList()
    },
    methods: {
        // 获取商品列表
        getList(){
            goodslist(this.storePrams).then(res=>{
                console.log(res.data.data);
                this.total = res.data.total
                this.shopList = res.data.data
            })
        },
        // 添加商品
        addNotice(e){
            if (e==='add') {
                this.editcompondata.type = 1
                this.editcompondata.id = null
                this.editcompondata.disblay = true
            }else{
                this.editcompondata.type = 2
                this.editcompondata.disblay = true
                this.editcompondata.id = e
            }
        },
        // 编辑商品
        edit(){

        },
        // 删除
        delBtn(id){
            this.$Modal.confirm({
                title: '提示',
                content: `您正在使用删除功能`,
                onOk: () => {
                    goodsdel({id:id}).then(res=>{
                        this.$Message.success(res.msg)
                        this.getList()
                    }).catch(err=>{
                        this.$Message.error(err.msg)
                    })
                },
            });
        },
        // 组件回调
        editcompongoToView(e){
            console.log('组件回调');
            this.getList()
            this.editcompondata.disblay = e
        },
        // 搜索
        searchList(){
            this.storePrams.page = 1
            this.getList()
        },
        // 分页
        pageChange(e){
            this.storePrams.page = e
            this.getList()
        }
    },
};
</script>
<style>
    .mode_box{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #1CBF6D;
    }
</style>
