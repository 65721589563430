<template>
    <div>
        <div class="form_box">
            <Form label-position="left" :label-width="100">
                <FormItem label="商品名称：">
                    <Input style="width: 400px" placeholder="请输入商品名称" v-model="formdata.goods_name"></Input>
                </FormItem>
                <FormItem label="商品图片：">
                    <div style="display:flex">
                        <div v-if="formdata.goods_img" class="pic_hezi">
                            <img :src="formdata.goods_img" alt="">
                        </div>
                        <div>
                            <Upload
                            ref="upload"
                            :show-upload-list="false"
                            :format="['jpg','jpeg','png']"
                            :max-size="2048"
                            :headers="headers"
                            :on-format-error="handleFormatError"
                            :on-success="uploadImgSuccess"
                            :on-progress="handleProgress"
                            multiple
                            :action="uploadImg">
                                <div class="uplads_box">
                                    <div style="text-align:center">
                                        <div style="margin-top:-10px"><Icon style="color:#9EA6B9" size="70" type="ios-add" /></div>
                                        <div class="font14" style="margin-top:-10px;color:#9CA7B9">点击上传</div>
                                        <div style="font-size:12px;color:#9CA7B9;margin-top:-15px">（193*175）</div>
                                    </div>
                                </div>
                            </Upload>
                        </div>
                    </div>
                </FormItem>
                <FormItem label="商品单位：">
                    <Input style="width: 400px" placeholder="请输入商品单位" v-model="formdata.goods_unit"></Input>
                </FormItem>

                <FormItem style="margin:0" label="商品价格："></FormItem>
                <Form style="padding:0 0 20px 100px" inline label-position="right" :label-width="70">
                    <div v-for="(item,index) of formdata.goods_prices" :key="index">
                        <FormItem label="规格名：">
                            <Input v-model="item.specs" style="width: 160px"></Input>
                        </FormItem>
                        <FormItem label="价格：">
                            <Input v-model="item.price"  type="number" style="width: 160px"></Input>
                        </FormItem>
                        <FormItem label="原价：">
                            <Input v-model="item.old_price"  type="number" style="width: 160px"></Input>
                        </FormItem>
                        <FormItem label="包装费：">
                            <Input v-model="item.pack_money" type="number" style="width: 160px"></Input>
                        </FormItem>
                        <FormItem label="库存：">
                            <Input v-on:input ="inputFunc($event,item)"  type="number" v-model="item.stock" style="width: 160px"></Input>
                        </FormItem>
                        <FormItem label="">
                            <Checkbox v-model="item.is_full" :true-value="1" :false-value="0">次日置满</Checkbox>
                        </FormItem>
                        <FormItem label="">
                            <Button @click="clearPrice(index)" style="width:60px;text-align:cemter" icon="md-remove"></Button><span style="margin-left:10px"></span>
                        </FormItem>
                    </div>
                    <div>
                        <Button @click="addprice" style="width:60px;text-align:cemter" icon="md-add"></Button><span style="margin-left:10px">最多添加10个</span>
                    </div>
                </Form>

                <FormItem label="商品属性："></FormItem>
                <Form style="padding:0 0 20px 100px" inline label-position="right" :label-width="120">
                    <div class="just_center" v-for="(item,index) of formdata.goods_attr" :key="index">
                        <FormItem label="属性名称：">
                            <Input v-model="item.attr_name" style="width: 160px"></Input>
                        </FormItem>
                        <Button @click="clearshop(index)" style="width:60px;text-align:cemter" icon="md-remove"></Button><span style="margin-left:10px"></span>
                        <div>
                            <FormItem label="请输入属性值：">
                                <Input v-for="(j,k) of item.values" :key="k" v-model="item.values[k]" class="mr20" style="width: 80px"></Input>
                                <Button @click="attributevalueBtn(item)" style="width:60px;text-align:cemter" icon="md-add"></Button><span style="margin-left:10px"></span>
                                <Button @click="clearItem(index)" style="width:60px;text-align:cemter" icon="md-remove"></Button><span style="margin-left:10px"></span>
                            </FormItem>
                        </div>
                    </div>
                    <div>
                        <Button @click="commodityattribute" style="width:60px;text-align:cemter" icon="md-add"></Button><span style="margin-left:10px">最多添加10个</span>
                    </div>
                </Form>

                <FormItem label="加料："></FormItem>
                <Form style="padding:0 0 20px 100px" inline label-position="right" :label-width="70">
                    <div v-for="(item,index) of formdata.food" :key="index">
                        <FormItem label="料名：">
                            <Input v-model="item.food_name" style="width: 160px"></Input>
                        </FormItem>
                        <FormItem label="价格：">
                            <Input v-model="item.price"  type="number" style="width: 160px"></Input>
                        </FormItem>
                        <FormItem label="库存：">
                            <Input v-on:input ="inputFunc1($event,item)"  type="number" v-model="item.stock" style="width: 160px"></Input>
                        </FormItem>
                        <FormItem label="">
                            <Checkbox v-model="item.is_full" :true-value="1" :false-value="0">次日置满</Checkbox>
                        </FormItem>
                        <FormItem label="">
                            <Button @click="clearFeeding(index)" style="width:60px;text-align:cemter" icon="md-remove"></Button><span style="margin-left:10px"></span>
                        </FormItem>
                    </div>
                    <div>
                        <Button @click="Feeding" style="width:60px;text-align:cemter" icon="md-add"></Button><span style="margin-left:10px">最多添加10个</span>
                    </div>
                </Form>

                <FormItem label="商品标签：">
                    <Input style="width: 400px" placeholder="请输入商品标签" v-model="formdata.label"></Input>
                </FormItem>
                <FormItem label="模式：">
                    <CheckboxGroup v-model="formdata.mode" class="fenbu">
                        <Checkbox v-for="(item,index) of modeList" :key="index" style="margin-left:15px" :label="item.id">{{item.mode_name}}</Checkbox>
                    </CheckboxGroup>
                </FormItem>
                <FormItem label="商品简介：">
                    <textarea v-model="formdata.desc" class="textar" name="" id="" cols="30" rows="10"></textarea>
                </FormItem>
                <FormItem label="商品轮播：">
                    <Upload
                        ref="upload"
                        :disabled="formdata.banner.length>=3?true:false"
                        :show-upload-list="false"
                        :format="['jpg','jpeg','png']"
                        :max-size="2048"
                        :headers="headers"
                        :on-format-error="handleFormatError"
                        :on-success="uploadImgSuccess1"
                        :on-progress="handleProgress"
                        multiple
                        :action="uploadImg">
                            <Button>点击上传</Button> <span>（最多上传3张图 372*235）</span>
                    </Upload>
                    <div class="img_da_box">
                        <div v-for="(item,index) of formdata.banner" :key="index">
                            <div class="img_box">
                                <Icon @click="closePic(index)" size="20" class="close_dw" type="ios-close-circle" />
                                <img :src="item" alt="">
                            </div>
                        </div>
                    </div>
                </FormItem>
            </Form>
        </div>
        <div class="bottom_Btn">
            <Button @click="barck" style="margin-right:30px">取消</Button>
            <Button @click="subBtn" type="primary">确认</Button>
        </div>
    </div>
</template>

<script>
    import {goodsadd,goodsdetails} from '@/api/shop.js'
    import config from '@/config/index'
    import {getCookies} from '@/utils/helper'
    export default {
        props: {titleSync:Object},
        data(){
            return{
                uploadImg:config.apiUrl+ '/adminapi/config/fileUpload',
                headers:{
                    'hlzw-saas-authori-zation':getCookies('hlzw_saas_token')
                },
                modeList:[
                    {id:1,mode_name:'堂食'},
                    {id:2,mode_name:'外卖'},
                    {id:3,mode_name:'自取'},
                ],
                formdata:{
                    goods_name:'',
                    vip_status:0,
                    is_vip_alone:0,
                    goods_img:'',
                    goods_unit:'',
                    goods_prices:[],
                    goods_attr:[],
                    food:[],
                    label:'',
                    mode:[],
                    desc:'',
                    banner:[],
                    limit_status:'',
                    limit_num:'',
                    limit_day:'',
                },
            }
        },
        created() {
            if (this.titleSync.id) {
                this.getDetail(this.titleSync.id)
            }
        },
        methods:{
            getDetail(id){
                goodsdetails({id:id}).then(res=>{
                    res.data.mode = res.data.mode.map(Number);
                    this.formdata = res.data
                })
            },
            // 商品轮播删除
            closePic(e){
                this.formdata.banner.splice(e,1)
            },
            // 添加商品价格
            addprice(){
                if (this.formdata.goods_prices.length<10) {
                    this.formdata.goods_prices.push({key:this.formdata.goods_prices.length==0?1:this.formdata.goods_prices[this.formdata.goods_prices.length-1].key+1,specs:'默认',old_price:'0',price:'0',vip_price:'0',pack_money:'0',stock:'999',stock_full:'999',is_full:0,})
                }else{
                    this.$Message.warning('最多添加10个')
                }
            },
            // 删除价格
            clearPrice(e){
                this.formdata.goods_prices.splice(e,1)
            },
            // 添加商品属性
            commodityattribute(){
                if (this.formdata.goods_attr.length<10) {
                    this.formdata.goods_attr.push({attr_name:'默认',values:['']})
                }else{
                    this.$Message.warning('最多添加10个')
                }
            },
            // 添加属性值
            attributevalueBtn(e){
                e.values.push('')
            },
            // 删除商品属性
            clearshop(e){
                this.formdata.goods_attr.splice(e,1)
            },
            // 删除属性值
            clearItem(e){
                if (this.formdata.goods_attr[e].values.length!=1) {
                    this.formdata.goods_attr[e].values.splice(this.formdata.goods_attr[e].values.length-1,1)
                }
            },
            // 添加商品加料
            Feeding(){
                if (this.formdata.food.length<10) {
                    this.formdata.food.push({key:this.formdata.food.length==0?1:this.formdata.food[this.formdata.food.length-1].key+1,food_name:'默认',price:'0',stock:'999',stock_full:'999',is_full:0,})
                }else{
                    this.$Message.warning('最多添加10个')
                }
            },
            // 删除加料
            clearFeeding(e){
                this.formdata.food.splice(e,1)
            },
            //文件上传类型错误
            handleFormatError () {
                this.$Message.warning('暂不支持上传此格式');
            },
            //编辑图片上传图片成功
            uploadImgSuccess(e){
                this.formdata.goods_img = e.data
                this.$Message.destroy()
                this.$Message.success(e.msg);
            },
            uploadImgSuccess1(e){
                this.formdata.banner.push(e.data)
                this.$Message.destroy()
                this.$Message.success(e.msg);
            },
            // 文件上传时
            handleProgress(){
                this.$Message.loading({
                    content: '上传中...',
                    duration: 0
                });
            },
            // 取消/返回
            barck(){
                this.$emit('titleSync',false)
            },
            // 保存按钮
            subBtn(){
                goodsadd(this.formdata).then(res=>{
                    this.$Message.success(res.msg)
                    this.barck()
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            }
        }
    }
</script>

<style scoped>
    .form_box{
        height: 76vh;
        overflow: auto;
    }
    .bottom_Btn{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        border-top: 1px solid #eee;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 50px;
        /* background: #000; */
    }
    .textar{
        max-width: 400px;
        min-width: 400px;
        max-height: 100px;
        min-height: 100px;
        outline: #57A3F3;
        padding: 0 10px;
        border-radius: 5px;
        border: 1px solid #DCDEE2;
    }
    .uplads_box{
        width: 105px;
        height: 105px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed #A0A4BD;
        background: #F7F8FA;
        border-radius: 5px;
        cursor: pointer;
    }
    .img_da_box{
        display: flex;
        align-items: center;
    }
    .img_box{
        position: relative;
        margin-top: 10px;
        width: 200px;
        height: 100px;
        margin-right: 20px;
        /* overflow: hidden; */
    }
    .img_box img{
        width: 100%;
        border-radius: 5px;
        height: 100%;
        object-fit: cover;
    }
    .attributBox{
        display: flex;
        flex-wrap: wrap;
    }
    .close_dw{
        position: absolute;
        top: -7px;
        right: -7px;
    }
    .mr20{
        margin-right: 20px;
    }
    .pic_hezi{
        width: 105px;
        height: 105px;
        border: 1px solid #999;
        overflow: hidden;
        border-radius: 5px;
        margin-right: 20px;
    }
    .pic_hezi img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .just_center{
        width: 100%;
        display: flex;
        /* align-items: center; */
    }
</style>