import request from '@/utils/request'

export function goodslist (data) {
    return request({
        url: '/store/goods/list',
        method: 'post',
        data
    })
}

export function goodsdel (data) {
    return request({
        url: '/store/goods/del',
        method: 'post',
        data
    })
}

export function goodsadd (data) {
    return request({
        url: '/store/goods/add',
        method: 'post',
        data
    })
}

export function goodsdetails (data) {
    return request({
        url: '/store/goods/details',
        method: 'post',
        data
    })
}